<template>
    <div>
        <el-dialog
            title="添加备注"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="0">
                <el-form-item prop="message">
                    <el-input
                        :rows="8"
                        type="textarea"
                        maxlength="200"
                        placeholder="请输入内容"
                        v-model="form.message">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    message: ''
                },
                rules: {
					message: [{required: true, message:'请输入备注', trigger: 'blur'}],
                },
                recordInfo: ''
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认添加备注？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.warningRecordAddMark,
                                method: "post",
                                params: {
                                    ...this.form,
                                    recordId: this.recordInfo.id
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$emit('success',this.recordInfo);
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>