<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">预警列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input-district-choose label="省市区" 
                    :province.sync="pageParam.params.provinceCode" 
                    :city.sync="pageParam.params.cityCode" 
                    :area.sync="pageParam.params.areaCode"></le-input-district-choose>
                <le-select-local-search label="预警状态" v-model="pageParam.params.alertRecordStatus" :options="warningStatusDic" />
                <le-select-local-search label="预警类型" v-model="pageParam.params.alertRuleId" :options="warningTypeDic" />
                <le-select-local-search label="本人处理" v-model="pageParam.params.myDealRecord" :options="myDealRecordDic" />
                <jd-datetime-range 
                    :large="true"
                    label="预警时间" 
                    :pickerOptions="pickerOptions"
                    valueFormat="yyyy-MM-dd HH:mm:ss" 
                    :minDate.sync="pageParam.params.alertStartTime" 
                    :maxDate.sync="pageParam.params.alertEndTime" />
            </le-search-form>
            <le-pagview ref="financeCompany" 
                @setData="setTableData" 
                :pagerCount="5" 
                :pageSizeDefault="12" 
                :pageParam="pageParam" 
                :isFixed="true" 
                :pageSizeArr="[12, 24, 48, 96]">
                <div class="a-flex-rfsfs a-flex-wrap a-w-100">
                    <warning-record-item v-for="(item) in tableData" :key="item.id" :recordInfo="item" @handleOperate="handleOperate"></warning-record-item>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                        <el-empty description="暂无记录"></el-empty>
                    </div>
                </div>
            </le-pagview>
        </el-card>
        <add-mark ref="addMark" @success="addMarkSuccess"></add-mark>
        <mark-list ref="markList"></mark-list>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import addMark from './child/addMark.vue'
    import markList from './child/markList.vue'
    import warningRecordItem from './child/warningRecordItem.vue';
    import util from '../../../src/utils/util'
    export default {
        components: {
            addMark,
            markList,
            warningRecordItem
        },
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.getWarningRecordPage,
                    method: "post",
                    params: {
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        alertRecordStatus: '',
                        alertRuleId: '',
                        myDealRecord: 0,
                        alertEndTime: this.$getDay.getToday() + ' 23:59:59',
                        alertStartTime: this.$getDay.getTodayBeforeDays(6) + ' 00:00:00',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
                warningStatusDic: [],
                warningTypeDic: [],
                myDealRecordDic: [{
                    label: "全部",
                    value: 0,
                }, {
                    label: "本人处理",
                    value: 1,
                }]
            }
        },
        created () {
            // this.pageParam.params.companyId = this.company.id
            this.$getDic('alertRuleType','select').then(res=>{ this.warningTypeDic = res; })
            this.$getDic('alertRecordStatus','select').then(res=>{ this.warningStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
            }),
        },
        methods: {
            setTableData(data) {
                this.tableData = data.map(item=>{
					return {
						...item,
						listKey: item.id + Date.now()
					}
				})
            },
            handlerRest() {
                this.pageParam.params = {
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    alertRecordStatus: '',
                    alertRuleId: '',
                    myDealRecord: 0,
                    alertEndTime: '',
                    alertStartTime: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.alertStartTime,this.pageParam.params.alertEndTime).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeCompany'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleOperate (datas) {
                switch (datas.type) {
                    case 'showMark':
                        this.showMarkList(datas.target)
                        break;
                    case 'addMark':
                        this.addMark(datas.target)
                        break;
                    case 'handleByMine':
                        // 本人处理
                        this.handleByMine(datas.target)
                        break;
                    case 'handleCloseElectric':
                        // 设备关电
                        this.handleCloseElectric(datas.target)
                        break;
                    case 'handleSpray':
                        this.handleSpray(datas.target, datas.type, datas.extentsParams)
                        break;
                    case 'handleOpenSpray':
                        this.handleSpray(datas.target, datas.type, datas.extentsParams)
                        break;
                    case 'handleCloseSpray':
                        this.handleSpray(datas.target, datas.type, datas.extentsParams)
                        break;
                    default:
                        break;
                }
            },
            addMark (item) {
                this.$refs['addMark'].recordInfo = item
                this.$refs['addMark'].dialogVisible = true
            },
            // 添加备注成功
            addMarkSuccess (item) {
                // this.pageParam.freshCtrl++
                this.showMarkList(item)
            },
            showMarkList (item) {
                this.$refs['markList'].recordInfo = item
                this.$refs['markList'].getMarkList()
                this.$refs['markList'].drawerVisible = true
            },
            handleByMine (item) {
                this.$confirm('是否确认本人处理？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.warningRecordDeal,
                        method: "post",
                        params: {
                            recordId: item.id
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{})
            },
            handleCloseElectric (item) {
                this.$confirm('是否确认立即关电？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.alertChargeDown,
                        method: "post",
                        params: {
                            recordId: item.id
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{})
            },
            // 一键喷淋
            handleSpray (item, type, extentsParams) {
                let txt = ''
                if(type == 'handleSpray') {
                    txt = '是否确认开启喷淋？(一直开启)'
                }else if(type == 'handleOpenSpray') {
                    txt = '是否确认开启喷淋？'
                }else if(type == 'handleCloseSpray') {
                    txt = '是否确认关闭喷淋？'
                }
                this.$prompt(txt, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    inputValidator: (value)=>{
                        if(!value) {
                            return '请输入操作密码'
                        }
                        if(value != 119) {
                            return '操作密码不正确'
                        }
                    }
                }).then(({value})=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.alertSpray,
                        method: "post",
                        params: {
                            recordId: item.id,
                            ...extentsParams
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{})
            },
            // 关闭喷淋
            handleCloseSpray (item) {
                this.$confirm('是否确认关闭喷淋？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.alertSpray,
                        method: "post",
                        params: {
                            recordId: item.id,
                            operateType: 2
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{})
            },
            exportfile () {
                let alertStartTime = this.pageParam.params.alertStartTime || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let alertEndTime = this.pageParam.params.alertEndTime || this.$getDay.getTodayBeforeDays(0) + ' 23:59:59'
                this.$exfile({
                    code: 13,
                    fileName: alertStartTime.split(' ')[0] + ' 至 ' + alertEndTime.split(' ')[0] + ' 预警列表',
                    startTime: alertStartTime.split(' ')[0],
                    endTime: alertEndTime.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: alertStartTime,
                        endDate: alertEndTime
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    

</style>