<template>
    <div>
        <el-drawer
            title="备注"
            :size="500"
            :visible.sync="drawerVisible"
            ref="drawer"
            direction="rtl">
            <div class="content">
                <div class="content-layout a-flex-1">
                    <el-timeline>
                        <el-timeline-item
                            color="#B9B9B9"
                            v-for="(item, index) in tableData"
                            :key="index"
                            :timestamp="item.createTime">
                            <div>
                                <span class="block" style="padding-bottom: 12px">{{ item.name }}</span>
                                <span>{{ item.content }}</span>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                        <el-empty description="暂无记录"></el-empty>
                    </div>
                </div>
                <div class="a-flex-rsbc">
                    <el-button class="a-flex-1" @click="$refs.drawer.closeDrawer()">关闭</el-button>
                    <el-button class="a-flex-1" type="primary" @click="addMark" v-if="recordInfo.state < 3">添加备注</el-button>
                </div>
            </div>
        </el-drawer>
        <add-mark ref="addMark" @success="addMarkSuccess"></add-mark>
    </div>
</template>

<script>
import addMark from './addMark.vue';
    export default {
    components: { addMark },
        data() {
            return {
                drawerVisible: false,
                tableData: [],
                recordInfo: ''
            };
        },
        created () {
        },
        methods:{
            getMarkList () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.warningRecordMarkList,
                    method: "post",
                    params: {
                        recordId: this.recordInfo.id
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.tableData = res.result.data
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            addMark () {
                this.$refs['addMark'].recordInfo = this.recordInfo
                this.$refs['addMark'].dialogVisible = true
            },
            // 添加备注成功
            addMarkSuccess () {
                this.getMarkList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content{
        padding: 0 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
        .content-layout{
            overflow-y: scroll;
            margin-bottom: 24px;
        }
        .content-layout::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        /deep/ .el-timeline{
            padding: 12px 0 0 6px !important;
        }
        /deep/ .el-timeline-item__tail{
            top: 16px !important;
            bottom: 4px !important;
            height: auto !important;
        }
        /deep/ .el-timeline-item{
            padding-bottom: 30px !important;
        }
        .dot{
            width: 15px;
            height: 15px;
            background: #B9B9B9;
            border-radius: 50%;
            border: 3px solid #ffffff;
        }
    }
</style>